<template>
  <div class="header-container">
    <div class="l-content">
      <span class="text"><router-link to="/guide">ASG后台管理系统<span class="version">{{ version
            }}</span></router-link></span>
    </div>
    <div class="r-content">
      <div class="single__router" @click="routerToGw">前往ASG官网<i class="el-icon-d-arrow-right"></i></div>
      <div class="wait__do">
        <li @click="() => { $router.push({ path: '/guide' }) }">
          <el-badge size="small" :max="99" :value="waitDoNumber" class="item">
            <span>任务待办</span>
          </el-badge>
        </li>
        <li @click="() => { $router.push({ path: '/authorization/task' }) }">
          <el-badge size="small" :max="99" :value="waitAuthNumber" class="item">
            <span>待审核</span>
          </el-badge>
        </li>
      </div>
      <div class="operation__container">
        <el-tooltip class="item" effect="dark" content="回到首页" placement="bottom">
          <li @click="handleCommand('1')">
            <i class="el-icon-s-home"></i>
          </li>
        </el-tooltip>
        <el-tooltip class="item" effect="dark" content="个人中心" placement="bottom">
          <li @click="handleCommand('4')">
            <i class="el-icon-s-tools"></i>
          </li>
        </el-tooltip>
        <el-tooltip class="item" effect="dark" content="自定义工作台" placement="bottom">
          <li @click="handleCommand('2')">
            <i class="el-icon-crop"></i>
          </li>
        </el-tooltip>
        <el-tooltip class="item" effect="dark" content="退出登录" placement="bottom">
          <li @click="handleCommand('3')">
            <i class="el-icon-switch-button"></i>
          </li>
        </el-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
import { confirmUpdate } from "@/api/login/index.js";
import { getToken } from "@/utils/auth";
import { getByTitle } from "@/api/config";
import { mapGetters } from "vuex";
export default {
  name: "CommonHeader",
  data() {
    return {
      version: ''
    };
  },
  computed:{
    ...mapGetters(['waitDoNumber', 'waitAuthNumber'])
  },
  created() {
    getByTitle('versionConfig').then(res => {
      this.version = res.data.find(item => item.system === 'admin')?.version ?? '无版本号';
    })
  },
  methods: {
    routerToGw() {
      window.location.href = `https://idvasg.cn/#/?${encodeURIComponent(getToken())}`;
    },
    handleCommand(command) {
      if (command === '1') {
        this.goManager();
      } else if (command === '2') {
        this.toCustom();
      } else if (command === '3') {
        this.logout();
      } else if (command === '4') {
        this.$router.push({ path: '/userInfo' })
      }
    },
    goManager() {
      this.$router.push({ path: '/guide' });
    },
    toCustom() {
      this.$router.push({ path: '/myCustomWorker' });
    },
    async logout() {
      try {
        const flag = await this.$confirm('您确定退出登录吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
        if (flag === 'confirm') {
          this.$store.commit("removeToken");
          sessionStorage.removeItem('baseImg');
          this.$store.commit("SET_WAITDO_NUMBER", null);
          this.$store.commit("SET_WAITAUTH_NUMBER", null);
          this.$router.push("/login");
          this.$message.warning("您已退出登录！");
        }
      } catch (error) {
        if (typeof error === 'string' && error === 'cancel') return;
      }
    },
    handleMenu() {
      this.$store.commit("collapseMenu");
    },
    confirm() {
      if (this.form.token) {
        confirmUpdate(this.form.email, this.form.password, this.form.token)
          .then(() => {
            this.$message.success("修改成功,请重新登录！");
            this.$store.commit("removeToken");
            this.$router.push("/");
          })
          .catch((err) => {
            this.$message.error(err.message)
          })
      } else {
        this.$message.error("请输入验证码！")
      }

    },
  },
};
</script>

<style scoped lang="less">
@activeColor: #f7e757;

.header-container {
  padding: 0 20px;
  background-color: #0D47A1;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .text {
    a {
      color: #fff;
      font-size: 18px;
      margin-left: 10px;
      font-weight: 500;

      .version {
        font-size: 12px;
        color: #d1d1d1;
        margin-left: 6px;
      }
    }
  }

  .r-content {
    display: flex;
    align-items: center;

    .wait__do {
      padding: 0 16px;
      border-left: 1px solid #bec4cf;
      display: flex;
      align-items: center;
      height: 34px;
      margin-left: 32px;

      li {
        font-size: 14px;
        margin: 0 14px;
        font-weight: 400;
        color: #fff;
        cursor: pointer;

        &:hover {
          color: @activeColor;
        }
      }
    }

    .operation__container {
      display: flex;
      height: 34px;
      align-items: center;
      padding-left: 18px;
      border-left: 1px solid #bec4cf;

      li {
        width: 34px;
        height: 34px;
        border: 1px solid #bec4cf;
        border-radius: 50%;
        margin: 0 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        box-sizing: border-box;

        i {
          font-size: 16px;
          color: #fff;
        }

        &:hover {
          border: 1px solid @activeColor;

          & i {
            color: @activeColor;
          }

        }
      }
    }

  }
}

span {
  width: 100%;
}

.form-body {
  padding: 0px 50px 20px;
}

.run-enter-active {
  animation: bounce-in .4s;
}

/* 离开的时候要激活的样式 */
.run-leave-active {
  animation: bounce-in .4s reverse;
}

@keyframes bounce-in {
  0% {
    transform: scale(0);
  }

  50% {
    transform: scale(1.3);
  }

  100% {
    transform: scale(1);
  }
}

.single__router {
  width: fit-content;
  height: 22px;
  padding: 5px 16px;
  background: #262E3B;
  color: #B9BDC4;
  font-size: 14px;
  display: flex;
  align-items: center;
  font-weight: 600;
  border-radius: 3px;
  cursor: pointer;

  &:hover {
    color: @activeColor;
  }
}

.info-body {
  padding: 12px;

  header {
    display: flex;
    align-items: flex-start;

    .info-columns {
      margin-left: 18px;
      display: flex;
      flex-direction: column;
      gap: 24px 0;

      h4 {
        font-size: 24px;
        font-weight: bold;

        i {
          margin-left: 6px;
          color: #4090EF;
          cursor: pointer;

          &:hover {
            color: #c4d5ef
          }
        }
      }

      p {
        color: #4090EF;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
          text-underline-offset: 0.2em;
        }
      }
    }
  }

  .fix_name_body {
    font-size: 22px;
    display: grid;
    grid-template-columns: auto 30px 30px;
    gap: 0 12px;
    align-items: center;
    margin: 24px 0;

    i {
      font-weight: bold;
      cursor: pointer;

      &.saveIcon {
        color: #4090EF;

        &:hover {
          color: #6c8fc5
        }
      }

      ;

      &.closeIcon {
        color: #f40;

        &:hover {
          color: rgb(231, 143, 143)
        }
      }
    }
  }


}
</style>
